<!-- 预约入库单 -->
<template>
    <div class="asnBill">
        <div ref="commonSearchRef">
            <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="全部" name="100"></el-tab-pane>
                <el-tab-pane
                    v-for="(item, index) in getTabAsnStates" :key="index"
                    :label="item.label" :name="item.value">
                <template v-slot:label>{{ getTabs(item) }}</template>
                </el-tab-pane>
            </el-tabs> -->
            <div class="publicSearch p-10">
                <div class="searchBar" :style="{ height: isMoreSearch ? 'auto' : '' }">
                    <div>
                        <!-- <span>入库单单号</span> @dblclick.native="handleDblclick" -->
                        <el-input style="width: 300px;" v-model="searchData.keyWords" size="mini" :placeholder="$t(`m.warehousingCommon.batchSearch`)" clearable></el-input>
                    </div>
                    <!-- <div v-if="activeName === '100'">
                        <el-select v-model="searchData.asnState" size="mini" filterable placeholder="入库状态" clearable>
                            <el-option v-for="(item, index) in asnStates" :key="index" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-select v-model="searchData.billSource" size="mini" filterable placeholder="入库类型" clearable>
                            <el-option v-for="(item, index) in asnStatusTypes" :key="index" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div> -->
                    <div>
                        <el-button @click="getAsnData" size="mini" icon="el-icon-search" type="primary">{{ $t(`m.common.search`) }}</el-button>
                        <el-button @click="clearSearchData" size="mini" icon="el-icon-refresh">{{ $t(`m.common.reset`) }}</el-button>
                    </div>
                </div>
                <!-- <span class="moreSearchBtn" :class="isMoreSearch ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" @click="isMoreSearch = !isMoreSearch"></span> -->
            </div>
            <div class="headerBtns">
                <!-- <el-button size="small" type="primary" icon="iconfont icon-xinzeng" @click="$router.push({ name: 'createAsnBill' })">
                    新增
                </el-button> -->
                <!-- <el-button size="small" type="primary" icon="iconfont icon-xinzeng" :disabled="!multipleSelection.length"
                @click="goToFastAcceptBtn">
                    收货
                </el-button> -->
                <!-- <el-dropdown class="mgl-10">
                    <el-button type="primary" icon="iconfont icon-print" size="small" plain>
                        打印<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>打印收货单</el-dropdown-item>
                        <el-dropdown-item>打印产品条码</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
            </div>
        </div>
        <div class="tableBox">
            <el-table
                ref="commonTableRef"
                tooltip-effect="dark myTooltips"
                :row-style="$parent.tableRowStyle"
                highlight-current-row
                stripe
                border
                :header-cell-style="$parent.tableHeaderColor"
                v-loading="loading.masterTable"
                :data="asnBills"
                @selection-change="handleSelectionChange"
                style="width: 100%"
                row-key="billNo"
                :height="dynamicsHeight(commonSearchRef, true, true, 10)"
                :expand-row-keys="expandRowKeys"
                @expand-change="(a,b) => handleExpandChange(a,b, 'item')"
            >
                <!-- <el-table-column type="selection" width="55" key="0" fixed="left"></el-table-column> -->
                <!-- <el-table-column type="index" label="序号" width="50"> </el-table-column> -->
                <el-table-column type="expand" key="1">
                    <template slot-scope="scope" v-if="scope.row.details">
                        <div class="p-10">
                            <el-table :data="scope.row.details" border style="width: 100%">
                                <el-table-column prop="img" :label="$t(`m.common.picture`)">
                                    <template slot-scope="scope">
                                        <el-image style="width: 63px; height: 63px; " :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]"></el-image>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="itemCode" label="SKU"> </el-table-column>
                                <el-table-column prop="itemName" :label="$t(`m.common.product_name`)"> </el-table-column>
                                <el-table-column prop="expectQuantity" :label="$t(`m.warehousingCommon.receivable_quantity_goods`)"> </el-table-column>
                                <!-- <el-table-column prop="acceptQuantity" label="已收量"> </el-table-column>
                                <el-table-column prop="ds" label="待收量">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.expectQuantity">{{ (Number(scope.row.expectQuantity||0) - Number(scope.row.acceptQuantity||0))||0 }}</span>
                                    </template>
                                </el-table-column> -->
                            </el-table>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="billNo" :label="$t(`m.warehousingCommon.serial_number`)" width="170">
                    <template slot-scope="scope">
                        <div class="textBtn" @click="toDetail(scope.row)">{{ $t(`m.warehousingCommon.receipt_inventory_inbound`) }}：<span> {{ scope.row.billNo }} </span></div>
                        <div>{{ $t(`m.warehousingCommon.linked_Order`) }}：<span> {{ scope.row.requestBuyBillNo }} </span></div>
                    </template>
                </el-table-column>
                <!-- <el-table-column show-overflow-tooltip prop="asnState" label="入库状态" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="asnStates.find(item =>  Number(item.value) == scope.row.asnState)?.type">
                            {{ asnStates.find(item => Number(item.value) == scope.row.asnState)?.label }}
                        </el-tag>
                    </template>
                </el-table-column> -->
                <el-table-column key="2" show-overflow-tooltip prop="rukxx" :label="$t(`m.warehousingCommon.inventory_info`)" width="150">
                    <template slot-scope="scope">
                        <span>{{ $t(`m.warehousingCommon.warehouse_storage`) }}：{{ scope.row.wareName }}</span>
                        <br>
                        <span>{{ $t(`m.common.inventory_type`) }}： {{ formatListDataByKey(scope.row.billType, false, asnStatusTypes, 'label', 'value' ) }} </span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="wlxx" :label="$t(`m.warehousingCommon.logistics_info`)" width="200" key="4">
                    <template slot-scope="scope">
                        <span>{{ $t(`m.warehousingCommon.logistics_provider`) }}：{{ scope.row.supplierName || formatListDataByKey(scope.row.carrierCode, false, carriers, 'carrierName', 'carrierCode' ) }}</span>
                        <br>
                        <span>{{ $t(`m.warehousingCommon.logistics_tracking_number`) }}： {{ scope.row.shipBillCode || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="wlgj" :label="$t(`m.warehousingCommon.logistics_trajectory`)" width="260">
                    <template slot-scope="scope">
                        <div class="tracking_nodes_content">
                            <div v-if="scope.row.trackingVO.trackingNodes && scope.row.trackingVO.trackingNodes.length > 0">
                                <Trajectory :list="scope.row.trackingVO.trackingNodes || []"/>
                            </div>
                            <div class="current_node" v-if="getCurrentNodeMessage(scope.row)">
                                <el-popover
                                    placement="top-start"
                                    title=""
                                    width="300"
                                    trigger="hover">
                                    <el-descriptions :colon="false">
                                        <el-descriptions-item>
                                            <div style="max-height: 300px; width: 100%; overflow: auto">
                                                <el-timeline style="padding-left: 10px; margin-top: 10px">
                                                    <el-timeline-item
                                                        v-for="(activity, index) in getDetails(scope.row)"
                                                        :key="index"
                                                        type="primary"
                                                        hide-timestamp
                                                        size="large"
                                                    >
                                                    <div style="font-size: 16px">{{ activity.node }}</div>
                                                    <div>
                                                        <div v-for="(item, itx) in activity.list" :key="itx">
                                                            <div class="exceptionInfo pt10">
                                                                <div>{{ item.trackingDesc }}</div>
                                                                <div class="flex" v-if="!!item.exceptionInfo">
                                                                    <i style="color:red;" class="el-icon-warning"></i>
                                                                    <div>{{ item.exceptionInfo }}</div>
                                                                </div>
                                                            </div>
                                                            <p class="pt10" style="color: #aba9a9;">{{ item.trackingDate }}</p>
                                                        </div>
                                                    </div>
                                                    </el-timeline-item>
                                                </el-timeline>
                                            </div>
                                        </el-descriptions-item>
                                    </el-descriptions>
                                    <span slot="reference">
                                        {{ getCurrentNodeMessage(scope.row) }}
                                        <i class="el-icon-arrow-down"></i>
                                    </span>
                                </el-popover>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="skuNumber" :label="$t(`m.warehousingCommon.sku_kind`)" width="100"></el-table-column>
                <el-table-column show-overflow-tooltip prop="totalNumber" :label="$t(`m.warehousingCommon.receivable_quantity_goods`)" width="200"></el-table-column>
                <!-- <el-table-column show-overflow-tooltip prop="signNumber" label="已收量" width="100"></el-table-column>
                <el-table-column show-overflow-tooltip prop="dsl" label="待收量" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.totalNumber">{{ (Number(scope.row.totalNumber) - Number(scope.row.signNumber))||0 }}</span>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column show-overflow-tooltip prop="wlxx" label="相关时间" width="260">
                    <template slot-scope="scope">
                        <div>创建时间：<span> {{ scope.row.createTime||'-' }} </span></div>
                        <div>收货时间：<span> {{ scope.row.expectTime||'-' }} </span></div>
                        <div>上架时间：<span> {{ scope.row.asnOutTime||'-' }} </span></div>
                    </template>
                </el-table-column> -->
                <el-table-column show-overflow-tooltip prop="memo" :label="$t(`m.common.remark`)" min-width="100">
                    <template slot-scope="scope">
                        <!-- <div style="text-align:left;color:#409EFF;width:250px;height:20px;width:120px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;">
                            <img
                                @click=" memoVisible = true; memoData = scope.row;"
                                style="width:15px"
                                src="../../../../assets/images/bj.png"
                            /> -->
                            {{ scope.row.memo }}
                        <!-- </div> -->
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="createTime" :label="$t(`m.common.creation_time`)" width="260"></el-table-column>
                <el-table-column :label="$t(`m.common.operation`)" fixed="right" min-width="100">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toDetail(scope.row)">{{ $t('m.common.Details') }}</el-button>
                        <!-- <el-button class="my-warning" type="text" v-if="scope.row.asnState === 0 ||scope.row.asnState === 2" @click="asnBillSub(scope.row)">
                        收货
                        </el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                class="pagination-bottom"
                style="text-align: right"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="searchData.pageNum"
                :page-sizes="[5, 10, 15, 20, 25]"
                :page-size="searchData.pageSize"
                layout="sizes,total, prev, pager, next"
                :total="searchData.total"
            ></el-pagination>
        </div>
        <!-- <el-dialog
        title="提示"
        :visible.sync="wlVisible"
        width="30%"
        >
        <iframe src="https://www.baidu.com/s?wd=75845805771725" frameBorder="0" width="900" scrolling="no" height="90"></iframe>
        <span>这是一段信息</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="wlVisible = false">取 消</el-button>
            <el-button type="primary" @click="wlVisible = false">确 定</el-button>
        </span>
        </el-dialog> -->
        <el-dialog :title="$t(`m.common.remark`)" :visible.sync="memoVisible" width="30%">
            <el-form :model="rowData">
                <el-form-item :label="$t(`m.warehousingCommon.order_number`)" :label-width="formLabelWidth">
                    <span>{{ memoData.billNo }}</span>
                </el-form-item>
                <el-form-item :label="$t(`m.common.remark`)" :label-width="formLabelWidth">
                    <el-input style="width:350px" type="textarea" rows="4" maxlength="200" show-word-limit v-model="memoData.memo" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="memoVisible = false">{{ $t(`m.msg.no`) }}</el-button>
                <el-button type="primary" @click="commitMemo">{{ $t(`m.msg.yes`) }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash'
import { formatListDataByKey, dynamicsHeight } from '@/utils/DateUtils.js'
import Trajectory from '@/components/ZhiHuiCompon/Trajectory/index.vue'

export default {
    name: 'AsnBill',
    components: { Trajectory },
    data() {
        return {
            commonSearchRef: null,
            searchRadio: '',
            queryForm: {},
            clientHeight: $(window).height(),
            noShipVisible: false,
            noShipInfo: {},
            memoData: {},
            memoVisible: false,
            asnPrintCode: 10,
            wlVisible: true,
            isMoreSearch: false,
            wareId: PF.getLocal('wareId'),
            loading: {
                masterTable: true,
            },
            rowData: {},
            carrierCode: null,
            searchData: {
                billNoType: 1, //搜索单据类型:1入库单2关联单号
                pageNum: 1,
                pageSize: 15,
                total: null,
                keyWords: '',
                wareCode: PF.getLocal('wareCode'),
                state: null,
                organizationId: PF.getLocal('organizationId', 'number'),
                supplierId: null,
                carrierId: null,
                asnState: null,
                billSource: null,
                billNo: '',
                asnType: '',
                platformCode: '',
                orderNo: '',
                createTimeArr: [],
                expectTimeArr: [],
                startCreateTime: '',
                endCreateTime: '',
                startExpectTime: '',
                endExpectTime: '',
            },
            asnBills: [],
            platforms: [],
            carriers: [],
            formLabelWidth: '120px',
            asnUdfHs: [],
            organizations: [],
            suppliers: [],
            multipleSelection: [],
            asnTypes: [],
            billSourceOptions: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '采购单',
                },
                {
                    value: '1',
                    label: '新建',
                },
                {
                    value: '2',
                    label: 'ERP导入',
                },
                {
                    value: '4',
                    label: '调拨',
                },
            ],
            activeName: '100',
            expandRowKeys: [],
            tabObj: {}
        };
    },
    watch: {
        carrierCode(newVal, oldVal) {
            this.searchData.carrierCode = newVal;
            this.getAsnData();
        },
    },
    computed: {
        dynamicsHeight,
        ...mapState(['reviewStates', 'asnStates', 'asnStatusTypes', 'qcStates']),
        getTabs() {
            return (item = {}) => {
              const {count1, count2, count3} = this.tabObj
              let label = item.label
              if(item.value == 0) {
                label = label + ((count1 || count1 == 0) ? `(${count1 <= 999 ? count1 : '999+'})` : '')
              } else if(item.value == 2) {
                label = label + ((count2 || count2 == 0) ? `(${count2 <= 999 ? count2 : '999+'})` : '')
              } else if(item.value == 3){
                label = label + ((count3 || count3 == 0) ? `(${count3 <= 999 ? count3 : '999+'})` : '')
              }

              return label
            }
        },
        getCurrentNodeMessage() {
            return (row = {}) => {
                const trackingCodes = row.trackingVO.trackingCodes || []
                if (trackingCodes.length > 0) {
                    const index = trackingCodes.length - 1
                    const currentNode = trackingCodes[index]
                    return currentNode.trackingDate + '  ' + currentNode.trackingDesc
                }
                return ''
            }
        },
        getDetails() {
            return (row)=> {
                const trackingCodes = row.trackingVO.trackingCodes || []
                const groupObj = _.groupBy(trackingCodes, 'trackingNode')
                return _.map(_.keys(groupObj), key => {
                    return {node: key, list: groupObj[key]}
                })
            }
        },
        getTabAsnStates() {
            return this.asnStates
        }
    },
    methods: {
        formatListDataByKey,
        // 批量收货
        goToFastAcceptBtn() {
            const isSlectLength = _.filter(_.cloneDeep(this.multipleSelection), item => item.asnState === 0 || item.asnState===2 ).length
            if (isSlectLength !== this.multipleSelection.length) {
                this.$message.warning('只有待收货或者部分收货才能批量收货！')
                return
            }
            const billNo = _.map(_.cloneDeep(this.multipleSelection), item => item.billNo).join(',')
            this.$router.push({ name: 'fastAccept', params: { billNo }})
        },
        // 查询列表所有的数据
        getAsnData() {
            var that = this;
            let searchData = PF.JSON(this.searchData);
            this.expandRowKeys = []
            that.loading.masterTable = true;
            let { keyWords } = this.searchData
            keyWords = keyWords ? keyWords.split(/,|，|\s+/).join(',') : null
            IOT.getServerData('/asn/masters/list', 'post', {...searchData, keyWords, asnType: 1}, ret => {
                that.loading.masterTable = false;
                if (ret.code === 200) {
                    var rows = ret.rows;
                    rows.forEach(row => {
                        var memo = row.memo;
                        var memos = [];
                        if (memo != null && memo.length > 0) {
                            memos = memo.split('&');
                            memo = memos[memos.length - 1];
                            row.memo = memo;
                            row.memos = memos;
                        }
                    });
                    this.asnBills = rows;
                    // this.asnBills.forEach(order => {
                    //     this.getAsnDetailData(order.billNo);
                    // });
                    this.searchData.total = ret.total;
                    this.searchData.pageNum = ret.pageNumber;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
            // 获取tab 待入库 收获中 已收货总数
            IOT.getServerData('/asn/masters/findCount', 'post', {type:1, wareCode: this.searchData.wareCode}, ret => {
              if (ret.code === 200) {
                this.tabObj = ret.data || {}
              } else {
                IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
              }
            }, true);
            that.$nextTick(() => {
                that.$refs.commonTableRef.doLayout(); // 解决表格错位
            });
        },
        // 详情
        toDetail(row) {
            this.$router.push({
                name: 'asnBillDetail',
                params: row,
            })
        },
        // 收货
        asnBillSub(row) {
            this.$router.push({
                name: 'fastAccept',
                params: row,
            })
        },
        // 单独点击展开触发
        handleExpandChange(row, expandList, type = 'item') {
            const obj = _.find(expandList, item => item.billNo === row.billNo)
            this.expandRowKeys = _.map(expandList || [], (it) => it.billNo);
            if (expandList.length > 0 && ((type === 'item' && obj) || type === 'all')) {
                let url = (url = '/asn/details/list');
                IOT.getServerData(url, 'post', { billNo: row.billNo }, ret => {
                    if (ret.code === 200) {
                        let list = ret.rows;
                        let deepAsnBills = _.cloneDeep(this.asnBills)
                        this.asnBills = _.map(deepAsnBills, i => {
                            if (i.billNo == row.billNo) {
                                i.details = list
                            }
                            return i
                        })
                    }
                });
            }
        },
        handleClick(tab, event) {
            this.searchData.keyWords = '';
            this.searchData.state = null;
            this.searchData.organizationId = null;
            this.searchData.supplierId = null;
            this.searchData.carrierId = null;
            this.searchData.asnState = null;
            this.searchData.billNo = '';
            this.searchData.billSource = '';
            this.searchData.asnType = '';
            this.searchData.platformCode = '';
            this.searchData.orderNo = '';
            this.searchData.createTimeArr = [];
            this.searchData.expectTimeArr = [];
            this.searchData.startCreateTime = '';
            this.searchData.endCreateTime = '';
            this.searchData.startExpectTime = '';
            this.searchData.endExpectTime = '';
            this.carrierCode = null;
            this.activeName = tab.name
            if (tab.name === '100') {
                this.searchData.asnState = null
            } else {
                this.searchData.asnState = tab.name
            }
            this.getAsnData()
        },
        pringAsnCode() {
            window.IOT.getServerData(
                '/asn/codes/print',
                'get',
                { wareId: this.wareId, quantity: this.asnPrintCode },
                ret => {
                    if (ret.code === 200) {
                        var strHTML = ret.data;
                        this.$parent.printHtml(strHTML, null);
                    } else {
                        window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                },
                true
            );
        },
        isDelete(asnMasterId) {
            this.$confirm('确认关闭吗？（不会删除）')
                .then(() => {
                    this.deleteOrder(asnMasterId);
                })
                .catch(() => {});
        },
        deleteOrder(asnMasterId) {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/masters/state6', 'get', { id: asnMasterId }, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('删除成功！', 'success');
                    this.getAsnData();
                } else {
                    window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },

        isDeleteDetail(id) {
            this.$confirm('确认关闭吗？ ')
                .then(() => {
                    this.deleteAsnDetail(id);
                })
                .catch(() => {});
        },
        deleteAsnDetail(id) {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/details/delete', 'get', { id: id }, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('删除成功！', 'success');
                    this.getAsnData();
                } else {
                    window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },

        allotAsn() {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/details/allotAsn', 'get', {}, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                } else {
                    window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        noShipCommit() {
            var noShipInfo = this.noShipInfo;

            window.IOT.showOverlay('提交中～～～');
            IOT.getServerData('/no/ships/add', 'post', noShipInfo, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('提交成功！', 'success');
                    this.noShipVisible = false;
                    this.noShipInfo = {};
                } else {
                }
            });
        },
        getAsnDetailData(billNo) {
            let url = (url = '/asn/details/list');
            IOT.getServerData(url, 'post', { billNo: billNo }, ret => {
                if (ret.code === 200) {
                    let list = ret.rows;
                    this.asnBills.forEach(order => {
                        if (order.billNo == billNo) {
                            order.details = list;
                        }
                    });
                    var asnBills = JSON.parse(JSON.stringify(this.asnBills));
                    this.asnBills = [];
                    this.asnBills = asnBills;
                } else {
                }
            });
        },
        commitMemo() {
            var that = this;
            that.memoVisible = false;
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData(
                '/asn/masters/memo',
                'get',
                {
                    asnMasterId: that.memoData.asnMasterId,
                    memo: that.memoData.memo,
                },
                ret => {
                    window.IOT.hideOverlay();
                    if (ret.code === 200) {
                        window.IOT.tips('提交成功！', 'success');
                        this.getAsnData();
                    } else {
                        window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                }
            );
        },

        getWlgj(row) {
            window.IOT.getServerData(
                '/kuaidiniaos/jscx',
                'get',
                {
                    expCode: row.carrierCode,
                    expNo: row.shipBillCode,
                },
                ret => {
                    debugger;
                    window.IOT.hideOverlay();
                },
                true
            );
        },
        updateShip(asn) {
            var that = this;
            that.rowData = asn;
            that.shipFormVisible = true;
        },
        commitShip() {
            var that = this;

            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData(
                '/asn/masters/addShipBillCode',
                'post',
                {
                    billNo: that.rowData.billNo,
                    asnMasterId: that.rowData.asnMasterId,
                    shipBillNo: that.rowData.shipBillCode,
                    shipCode: that.rowData.carrierCode,
                },
                ret => {
                    window.IOT.hideOverlay();
                    if (ret.code === 200) {
                        window.IOT.tips('提交成功！', 'success');
                        this.getAsnData();
                    } else {
                        window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                }
            );
        },
        // isDelete(asnMasterId) {
        //             this.$confirm('确认删除吗？')
        //                 .then(() => {
        //                     this.deleteOrder(asnMasterId)
        //                 })
        //                 .catch(() => {});
        //         },
        //       deleteOrder(asnMasterId) {
        //           window.IOT.showOverlay('提交中～～～')
        //           window.IOT.getServerData('/asn/masters/delete', 'get', {id: asnMasterId}, (ret) => {
        //               window.IOT.hideOverlay()
        //               if (ret.code === 200) {
        //                   window.IOT.tips('删除成功！', 'success')
        //                   this.getAsnData();
        //               } else {
        //                   window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
        //               }
        //           })
        //       },
        handleSizeChange(val) {
            this.searchData.pageSize = val;
            this.getAsnData();
        },

        handleCurrentChange(val) {
            this.searchData.pageNum = val;
            this.getAsnData();
        },
        clearSearchData() {
            this.searchData.keyWords = '';
            this.searchData.state = null;
            this.searchData.organizationId = null;
            this.searchData.supplierId = null;
            this.searchData.carrierId = null;
            this.searchData.asnState = null;
            this.searchData.billNo = '';
            this.searchData.billSource = '';
            this.searchData.asnType = '';
            this.searchData.platformCode = '';
            this.searchData.orderNo = '';
            this.searchData.createTimeArr = [];
            this.searchData.expectTimeArr = [];
            this.searchData.startCreateTime = '';
            this.searchData.endCreateTime = '';
            this.searchData.startExpectTime = '';
            this.searchData.endExpectTime = '';
            this.carrierCode = null;
            this.getAsnData()
        },
        getPlatformData() {
            IOT.getServerData('/platforms/findByWareId', 'get', { wareId: PF.getLocal('wareId', 'number') }, ret => {
                if (ret.code === 200) {
                    this.platforms = ret.rows;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        getCarrierData() {
            IOT.getServerData('/carriers/allList', 'get', {}, ret => {
                if (ret.code === 200) {
                    this.carriers = ret.rows;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        resetForm(formName) {
            // 重置
            this.$refs[formName].resetFields();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        commitFile() {
            IOT.showOverlay('保存中，请稍等...');
            let list = PF.JSON(this.multipleSelection);
            let billNos = [];
            for (let i = 0; i < list.length; i++) {
                billNos.push(list[i].billNo);
            }
            IOT.getServerData('/asn/masters/file', 'post', { billNos: billNos }, ret => {
                IOT.hideOverlay();
                this.multipleSelection = [];
                if (ret.code === 200) {
                    IOT.tips('归档成功！', 'success');
                    this.getAsnData();
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        getCode() {
            var that = this;
            document.onkeypress = function(e) {
                that.getAsnData();
            };
        },
    },
    created() {
        this.getAsnData();
        this.getPlatformData();
        this.getCarrierData();
        PF.getSysParamUdfData('asnUdfHs', [], (data, udfs) => {
            this.asnUdfHs = udfs;
        });
        PF.getOrganizations(rows => {
            this.organizations = rows;
        });
        PF.getSuppliers(rows => {
            this.suppliers = rows;
        });
        PF.getDataDictUdfData('asnType', rows => {
            this.asnTypes = rows;
        });
        PF.getCarriers(rows => {
            this.carriers = rows;
        });
        //   this.getCode();
    },
    mounted() {
        this.$nextTick(() => {
            this.commonSearchRef = this.$refs.commonSearchRef
        })
    },
};
</script>

<style scoped lang="less">
.tracking_nodes_content {
  width: 100%;
  overflow-x: auto;

  .current_node {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    color: #aba9a9
  }
}

</style>
